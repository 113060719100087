<template>
  <div class="pa-0 ma-0">
    <v-data-table
      :headers="headers"
      :items="alarms"
      style="width: 100%"
      :dense="dense"
    >
      <template v-slot:[`item.value`]="{ item }">
        <span v-if="item.type == 0">{{ item.levelLimit }}</span>
        <span v-if="item.type == 1">{{ item.changeLimit }}</span>
        <span v-if="item.type == 2">N/A</span>
        <span v-if="item.type == 3">N/A</span>
        <span v-if="item.type == 4">{{ item.changeLimit }}h</span>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <span>
          <v-icon color="primary"> mdi-chart-sankey </v-icon>
          {{ $t(`alarms.types.${item.type}`) }}
        </span>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          @click="$router.push(`/alarms/${item.alarmId}`)"
          v-if="permitted('Alarms.Update') && item.companyId == companyId"
        >
          <v-icon color="primary">mdi-pen</v-icon>
        </v-btn>

        <delete-dialog
          :dialog="removeDialog"
          v-on:cancel-dialog="removeDialog = false"
          v-on:confirm-delete="remove(item.alarmId)"
        />

        <v-btn
          icon
          @click="removeDialog = true"
          v-if="permitted('Alarms.Delete') && item.companyId == companyId"
        >
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DeleteDialog from "../common/DeleteDialog.vue";

export default {
  name: "AlarmList",

  props: {
    noFetch: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DeleteDialog,
  },

  data() {
    return {
      removeDialog: false,
      headers: [
        { text: this.$t("alarms.fields.name"), value: "name" },
        { text: this.$t("alarms.fields.type"), value: "type" },
        { text: this.$t("alarms.fields.message"), value: "message" },
        { text: this.$t("alarms.fields.value"), value: "value" },
        { text: this.$t("alarms.fields.createdAt"), value: "createdAt" },
        { text: "", value: "actions" },
      ],
    };
  },

  methods: {
    ...mapActions("alarms", ["getAlarms", "deleteAlarm"]),

    async remove(id) {
      await this.deleteAlarm(id);
      this.removeDialog = false;
    },
  },

  computed: {
    ...mapState("alarms", ["alarms"]),
    ...mapGetters("users", ["companyId"]),
  },

  async created() {
    if (!this.noFetch) {
      await this.getAlarms();
    }
  },
};
</script>
